.App {
    display: flex;
    justify-content: center;
    background-color: antiquewhite;
    align-items: center;
    height: 100vh;
  }
  
  .tip-container {
    text-align: center;
  }
  
  input[type="number"] {
    padding: 10px;
    margin-right: 10px;
  }
  
  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  